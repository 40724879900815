import React from "react"
import Layout from "../components/layout/Layout.js"
import { Column100 } from "../components/column/Column.js"
import { BlogRow, Row } from "../components/row/Row.js"
import { TextMedium } from "../components/text/Text"
import { BlogTitle, Title } from "../components/title/Title"
import Seo from '../components/seo'
import { Section } from "../components/section/Section.js"


function SubscribeSuccess () {
  return (
    <Layout>
      <Seo
        title="Thanks for Subscribing!"
        description="Thanks for subscribing to our newsletter! Please check your email address for a confirmation message."
      />
      <Section>
        <BlogRow>
            <BlogTitle isPrimary title="Thanks for subscribing to our newsletter!" />
            <TextMedium textMedium="Please check your email address for a confirmation message." />
            <TextMedium
              textMedium="We send 1-2 newsletters per month, mostly sharing our latest content. From time 
              to time, we organize live webinars or weekend courses." /> 
            <TextMedium textMedium="All our subscribers receive discount codes for these sessions." />
            <TextMedium textMedium="Thanks again and happy reading!" />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default SubscribeSuccess